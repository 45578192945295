import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import { ActivityType, AlertContent } from "helper";

const NotificationAlerts = ({ isDrawer = false, data, handleAlertClick, handleDismiss }) => {
  return (
    <MDBox
      mb={2}
      pt={2}
      sx={{
        maxHeight: isDrawer === true ? "100%" : { xs: "300px", sm: "459px" },
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {data?.map((notification) => {
        const getColor = (type) => {
          switch (type) {
            case ActivityType.NewBuyer:
              return "error";
            case ActivityType.NewDistributor:
              return "warning";
            case ActivityType.DailyOrderSync:
              return "info";
            case ActivityType.WeeklyOrderSync:
              return "secondary";
            case ActivityType.ProductPurchase:
              return "success";
            default:
              return "default";
          }
        };
        return (
          <MDAlert
            color={getColor(notification?.type)}
            key={notification.id}
            dismissible
            onClick={handleAlertClick}
            onClose={(e) => {
              handleDismiss(e, notification);
            }}
            sx={{
              mb: 1,
              wordWrap: "break-word",
              whiteSpace: "normal",
              fontSize: { xs: "0.875rem", sm: "1rem" },
            }}
          >
            {AlertContent(notification.description, notification.createdAt)}
          </MDAlert>
        );
      })}
    </MDBox>
  );
};

export default NotificationAlerts;
