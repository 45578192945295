import { formatDistanceToNow } from "date-fns";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export const AlertContent = (name, timestamp) => (
  <MDBox sx={{ cursor: "pointer" }}>
    <MDTypography variant="body2" color="white">
      <span dangerouslySetInnerHTML={{ __html: name }} />
    </MDTypography>
    <MDTypography variant="caption" color="white" sx={{ opacity: 0.7 }}>
      {formatDistanceToNow(new Date(timestamp), { addSuffix: true })}
    </MDTypography>
  </MDBox>
);
