export const AccountType = {
  ADMIN: "admin",
  BRAND: "brand",
  DISTRIBUTOR: "distributor",
};

export const SourceType = {
  QUICKBOOKS: "quickbooks",
  FAIRE: "faire",
};

export const ActivityType = {
  NewBuyer: "new_buyer",
  NewDistributor: "new_distributor",
  DailyOrderSync: "daily_order_sync",
  WeeklyOrderSync: "weekly_order_sync",
  ProductPurchase: "product_purchase",
};

export const ExternalOrderHeaders = [
  "Order Id",
  "Order Date",
  "Product Name",
  "SKU",
  "Description",
  "Unit Price",
  "Quantity",
  "Buyer Name",
  "City",
  "Country",
  "Country Code",
  "Country Sub Division Code",
  "Line 1",
  "Line 2",
  "Postal Code",
  "State",
];
