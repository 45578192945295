import apiClient from "api-client";
import { ADMIN_USER } from "api-client/endpoints";

export const handleImpersonating = async (id) => await apiClient.get(ADMIN_USER, { id });

export function getLocationObject(mailingAddress) {
  const addressParts = mailingAddress.split(",");
  const line1 = addressParts[0] ? addressParts[0].trim() : "";
  const city = addressParts.length >= 3 ? addressParts[addressParts.length - 3].trim() : "";
  const countrySubDivisionCode =
    addressParts.length >= 2 ? addressParts[addressParts.length - 2].split(" ")[0].trim() : "";
  const postalCode =
    addressParts.length >= 2
      ? addressParts[addressParts.length - 2].match(/\d+/)
        ? addressParts[addressParts.length - 2].match(/\d+/)[0]
        : ""
      : "";
  const country = addressParts.length >= 1 ? addressParts[addressParts.length - 1].trim() : "";

  return {
    line1,
    city,
    countrySubDivisionCode,
    postalCode,
    country,
  };
}
